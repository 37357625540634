import React from "react";
import "./style-quote.css";

function Body(props) {
  return (
    <div id="body" className="container-fluid">
      <div id="education" className="row ">
        <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/Polygons.png"></img>
        <div className="row ">
          <h3 id="educationTitle" className="title">
            Education
          </h3>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="container">
              <div className="row ">
                <div className="col col-lg  degree-cs">
                  <h4>{props.education.degree.masters.major}</h4>
                  <h3 className="">
                    {props.education.degree.masters.university}, <br />
                    {props.education.degree.masters.year}
                  </h3>
                </div>
              </div>
              <div className="row ">
                <div className="col col-lg  degree-ai">
                  <h4>G{props.education.degree.masters.minor}</h4>
                  <h3>
                    {props.education.degree.masters.university}, <br />
                    {props.education.degree.masters.year}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col col-lg degree-b-cs">
                  <h4>G{props.education.degree.bachelors.major}</h4>
                  <h3>
                    {props.education.degree.bachelors.university}, <br />
                    {props.education.degree.bachelors.year}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="projects" className="row align-items-center">
        <div className="col-lg  align-items-center">
          <div className="">
            <h4>Data structures API</h4>
            <h3>Python | Flask</h3>
          </div>
        </div>
        <div className="col-lg">
          <div className=" ">
            <h4>Reward Point Manager</h4>
            <h3>Python | Flask</h3>
          </div>
        </div>
        <div className=" col-lg ">
          <div className="">
            <h4>Data structures API</h4>
            <h3>Python | Flask</h3>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Body;
