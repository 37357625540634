import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

function MySlider(props) {
  const { itemIndex, setItemIndex } = useState(0);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    lazyload: true,
    speed: 300,
    slidedToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div id="slider" className="container">
      <div className="row slider-section">
        <div className="row ">
          <p className="title">{props.maintitle}</p>
        </div>
        <div className="row text-center">
          <div className="col-md-6 align-self-center ">
            <Slider {...settings} className="slider-container ">
              <div>
                <div className="slider-item ">
                  <h3>{props.projects.p1.title}</h3>
                  <h4>{props.projects.p1.tech}</h4>
                  <p>{props.projects.p1.description}</p>
                </div>
              </div>
              <div>
                <div className="slider-item">
                  <h3>{props.projects.p2.title}</h3>
                  <h4>{props.projects.p2.tech}</h4>
                  <p>{props.projects.p2.description}</p>
                </div>
              </div>
              <div>
                <div className="slider-item">
                  <h3>{props.projects.p3.title}</h3>
                  <h4>{props.projects.p3.tech}</h4>
                  <p>{props.projects.p3.description}</p>
                </div>
              </div>
              <div>
                <div className="slider-item">
                  <h3>{props.projects.p4.title}</h3>
                  <h4>{props.projects.p4.tech}</h4>
                  <p>{props.projects.p4.description}</p>
                </div>
              </div>
            </Slider>
          </div>
          <div className="col-md-6 text-center">
            <img src={props.illustration}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MySlider;
