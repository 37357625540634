import React from "react";
import { FaLinkedin } from "react-icons/fa";

import "../styles.css";

function Nav() {
  return (
    <div className="container">
      <div className="row nav-bar">
        <div className="col-lg logo text-center">
          <a href="index.html">Manoj Ponagandla</a>
        </div>
        <div className="col-lg">
          <div className="row nav-links text-center">
            <div className="col-lg ">
              <a href="#body">Education</a>
            </div>
            <div className="col-lg ">
              <a href="#slider">Projects</a>
            </div>

            <div className="col-lg ">
              <a href="#skills">Skills</a>
            </div>
          </div>
        </div>

        <div className="col-lg text-center ">
          <div className="container social ">
            <a
              id="linkedin-button"
              href="https://www.linkedin.com/in/manojponagandla/"
              target="_blank"
            >
              <i className="fab fa-linkedin linkedin social-logos "></i>
            </a>
            <a href="https://github.com/mjay768" target="_blank">
              <i className="fab fa-github-square github social-logos"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
