import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft, FaReact } from "react-icons/fa";

function Skills(props) {
  const { itemIndex, setItemIndex } = useState(0);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    lazyload: true,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: 0,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div id="skills" className="container">
      <div className="row skills-section text-center">
        <div className="row text-center">
          <p className="title">{props.skills[0]}</p>
        </div>
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="skill-item">
              <i class="fab fa-5x fa-react react-icon"></i>
              <h4>React.js</h4>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/python.png"></img>
              <h4>Python</h4>
            </div>
          </div>

          <div id="java" className="col-md-3 col-6">
            <div className="skill-item java">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/java.png"></img>
              <h4>Java</h4>
            </div>
          </div>
          <div className="col-md-3 col-6 skill-item">
            <div className="">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/c-icon.png"></img>
              <h4>C</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row skills-section text-center">
        <div className="row text-center">
          <p className="title">{props.skills[1]}</p>
        </div>
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="skill-item">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/aws-logo.svg"></img>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/git-logo.png"></img>
            </div>
          </div>

          <div id="mysql" className="col-md-3 col-6">
            <div className="skill-item mysql">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/mysql.png"></img>
            </div>
          </div>
          <div className="col-md-3 col-6 skill-item">
            <div className="">
              <img src="https://pictures-manoj-ponagandla.s3.amazonaws.com/logos/ci-cd.png"></img>
              <h4>CI/CD</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
