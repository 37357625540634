import React from "react";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { firebase } from "@firebase/app";
import "@firebase/database";
import emailjs from "emailjs-com";

var config = {
  apiKey: "AIzaSyDqGsPH1YIE6LmfmzF8HVX07cDXNLSq7lk",
  authDomain: "my-portfolio-manoj.firebaseapp.com",
  projectId: "my-portfolio-manoj",
  storageBucket: "my-portfolio-manoj.appspot.com",
  messagingSenderId: "55286497369",
  appId: "1:55286497369:web:33fe763bfde2c5c7d07148",
  measurementId: "G-L7PRKDFC4Z",
};
firebase.initializeApp(config);
console.log("Firebase initialization passed");
var database = firebase.database();

function writeContactInfo(values) {
  let vals = JSON.parse(values);
  var useremail = vals.email;
  console.log(useremail);
  console.log(vals.name);
  database
    .ref("contacts/" + vals.name)
    .set({
      email: vals.email,
      message: vals.message,
    })
    .then((result) => {
      console.log(result.text);
    })
    .catch((error) => {
      console.error(error.text);
    });
  SendEmail(vals.name, vals.email, vals.message);
}

const CustomTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-floating mb-3">
      <input
        id="floatingInput"
        className="form-control text-area"
        {...field}
        {...props}
      />
      <label for="floatingInput" htmlFor={props.id || props.name}>
        {label}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
const CustomTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-floating mb-3">
      <textarea
        id="floatingTextArea"
        className="form-control"
        {...field}
        {...props}
      ></textarea>
      <label for="floatingTextArea" htmlFor={props.id || props.name}>
        {label}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const SendEmail = (name, email, message) => {
  emailjs
    .send(
      "service_portfolio",
      "template_a5c2rw8",
      {
        from_name: "Manoj Ponagandla",
        to_name: name,
        to_email: email,
        message:
          "Thank you taking time to go through my profile and submitting your information. I will get back to you soon. ",
        reply_to: "mrponagandla@gmail.com",
      },
      "user_2MJaxHlH0fVqDssNVUeUF"
    )
    .then((result) => {
      console.log(result.text);
    })
    .catch((error) => {
      console.error(error.text);
    });

  emailjs.send("service_portfolio", "template_077oky1", {
    from_name: name,
    to_name: "Manoj Ponagandla",
    to_email: "mrponagandla@gmail.com",
    message: message,
    reply_to: email,
  });
};

function ContactMe() {
  return (
    <div className="container ">
      <div className="row footer">
        <div className="col-lg  connectwithme text-center">
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(3, "Atlest 3 charancters, Please")
                .max(30, "Not too long, Please")
                .required("*required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("*required"),
              message: Yup.string().max(
                "300",
                "Too long for now. Please explain more later."
              ),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                writeContactInfo(JSON.stringify(values, null, 2));
                alert(
                  "Thanks for submitting. Please don't forget to check spam folder."
                );

                resetForm();
                setSubmitting(false);
              }, 1000);
            }}
          >
            {(props) => (
              <Form className="form-style text-center">
                <h1 className="title">Connect with me</h1>
                <CustomTextInput
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Please enter your name"
                />
                <CustomTextInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="name@domail.com"
                />
                <CustomTextArea
                  label="Message"
                  name="message"
                  type="text"
                  placeholder="Type here in under 300 characters if you'd like to say something"
                />
                <button
                  id="submitbtn"
                  type="submit"
                  className="btn btn-primary"
                >
                  {props.isSubmitting ? "Loading..." : "Submit"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
