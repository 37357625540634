import React from "react";

import Nav from "./components/Navbar";
import Header from "./components/Header";
import "./styles.css";
import Body from "./components/Body";
import MySlider from "./components/Slider";
import Skills from "./components/Skills";
import ContactMe from "./components/ContactMe";

function App() {
  const maintitle = "Projects";
  const illustation =
    "https://career-docs-manoj.s3.amazonaws.com/project-illustration.png";
  const projects = {
    p1: {
      title: "Data Structures API",
      tech: "Python | Flask | API",
      description:
        "This repository contains python programs for common algorithms and data structures, but with some added features.",
    },
    p2: {
      title: "Reward Points",
      tech: "Python | Flask | API",
      description:
        "Developed a Restful API that provides user points functionality for rewards management.",
    },
    p3: {
      title: "Smart Surveillance Camera",
      tech: "Python | Raspberry Pi3 | OpenCV",
      description:
        "Built a surveillance system that captures images when there is motion, and stores on cloud.",
    },
    p4: {
      title: "Portfolio Webpage",
      tech: "React.js | HTML & CSS",
      description:
        "The webpage you are seeing right now is built using React.js",
    },
  };

  const education = {
    degree: {
      masters: {
        major: "Master's Computer Science",
        minor: "raduate Certification - Artificial Intelligence",
        university: "University of Missouri - St. Louis",
        year: "Spring 2019 - Fall 2020",
      },
      bachelors: {
        major: "Bachelor's Computer Science<",
        university: "Anurag Group of Institutions",
        year: "2014 - 2018",
      },
    },
  };

  // const skills= {"1":"Programming Skills",2:"Technologies"}
  const skills = ["Programming Skills", "Technologies"];

  return (
    <div className="App">
      <div className="container-fluid">
        <Nav />
        <Header />
        <Body education={education} />
        <MySlider
          projects={projects}
          maintitle={maintitle}
          illustration={illustation}
        />
        <Skills
          projects={projects}
          maintitle="Programming Skills"
          skills={skills}
          illustration={illustation}
        />
        <ContactMe />
      </div>
    </div>
  );
}

export default App;
