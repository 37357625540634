import React, { useState, useEffect } from "react";
import "./style-quote.css";

function Header() {
  const [word1, setWord1] = useState("Computer Enthusiast");
  const [word2, setWord2] = useState("Fitness Influencer");
  const [word3, setWord3] = useState("a Friendly Human Being");
  const array_word1 = [
    "Computer Lover",
    "Passionate Developer",
    "Computer Enthusiast",
  ];
  const array_word3 = [
    "a Friendly Human Being",
    "an Environmental Activist",
    "a Kind Soul",
  ];

  const array_word2 = ["Fitness Influencer", "Motivator", "Public Speaker"];
  let i = 0,
    j = 0,
    k = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setWord1(array_word1[i]);
      //setWord2(array[i]);
      if (i === 2) i = 0;
      else i += 1;
    }, 4500);
    const interval2 = setInterval(() => {
      setWord2(array_word2[j]);
      //setWord2(array[i]);
      if (j === 2) j = 0;
      else j += 1;
    }, 4000);
    const interval3 = setInterval(() => {
      setWord3(array_word3[k]);
      //setWord2(array[i]);
      if (k === 2) k = 0;
      else k += 1;
    }, 5000);
    return () => clearInterval(interval, interval2, interval3);
  }, []);

  return (
    <div id="header" className="container ">
      <div className="row  ">
        <div className="col header">
          <p>
            I am a <br /> {word1}, <br /> {word2} &
            <br />
            {word3}.
          </p>
          {/* <h1>I am a</h1>
          <h1>Computer Enthusiast,</h1>
          <h1>Fitness Influencer,</h1>
          <h1>Friendly Human Being.</h1> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
