import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import GoogleAnalytics from "./GoogleAnalytics";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
ReactDOM.render(
  <React.StrictMode>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-H3DYS833XQ"
    ></script>
    <GoogleAnalytics />
  </React.StrictMode>,
  document.getElementById("google-analytics-tag")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
