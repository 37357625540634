import React from "react";

function GoogleAnalytics() {
  window.dataLayer = window.dataLayer || [];
  const gtag = () => {
    window.dataLayer.push(arguments);
  };

  return (
    <div>
      {/* Global site tag (gtag.js) - Google Analytics  */}

      {gtag("js", new Date())}

      {gtag("config", "G-H3DYS833XQ")}
    </div>
  );
}

export default GoogleAnalytics;
